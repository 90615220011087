import React, {useState, useEffect,useContext} from 'react'
import { Icon, Button,Form,Modal,Input, Dropdown } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {useToasts } from 'react-toast-notifications';
import { AppContext } from '../../../../Context/AppContext';
import moment from "moment";
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";  
import { getBuildingLookup,getCriticalUnitServiceDetails,UpdateCriticalUnitData,
  deleteCriticalUnit } from "../../../../constants";


const MDL_UPDATE_CRITICAL_UNIT = ({set_mdl_open,SingleCriticalData}) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const{userInfo}=useContext(AppContext);
  const[user]=userInfo;
  const [building_units,setBuildingUnits] = useState([]);
  const [ pestData, setpestData ] = useState([]);
  const [buildingInfo, setBuildingInfo] = useState([]);
  const [selected_building, setSelectedBuilding] = useState([]);
  const [ServiceDate, setNewServiceDate] = useState(moment(SingleCriticalData.last_service_date).toDate());
  const [currentDate, setNewDate] = useState(moment(SingleCriticalData.date_reported).toDate());
  const [errors, set_errors] = useState({});
  const[isLoading,setLoading]=useState(false);
  const[fetchLOcationData,setfetchLOcationData]=useState(true);
  const[fetchUnitData,setfetchUnitData]=useState(true);
  const { addToast } = useToasts();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [ var_critical_info, set_critical_info ] = useState(SingleCriticalData)

   /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    fetchBuildingInfo()
  }, [])

  useEffect(() => {
    if (fetchLOcationData && buildingInfo.length > 0) {
      const formattedAddress = `${SingleCriticalData.building_address} (${SingleCriticalData.location_number})`;
  
      // Find the building that matches the formatted address
      const foundBuilding = buildingInfo.find(
        (building) => building.text === formattedAddress
      );
  
      if (foundBuilding) {
        setSelectedBuilding(foundBuilding);
      }
  
      setfetchLOcationData(false);
    }
  }, [fetchLOcationData, buildingInfo, SingleCriticalData]);

  useEffect(() => {
    if(fetchUnitData && selected_building?.units){
      const unit = selected_building?.units.find(item => item.text === var_critical_info.unit_number.toString());
      if (unit) {
        setBuildingUnits(unit.value); // Set the default value
        setfetchUnitData(false)
      }
    }
  }, [var_critical_info,selected_building])


  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/
  async function fetchBuildingInfo(searchQuery) {
    try {
      const response = await fetch(getBuildingLookup + `?q=${searchQuery || SingleCriticalData?.building_address}`);
      if (!response.ok) {
        throw new Error("Failed to fetch building info");
      }
      const data = await response.json();
      if (data.code == 200) {
        const formattedBuilding = data.data.map(building => ({
          value: building.value,
          text: `${building.text} (${building.location_number})`,
          customer_name:`${building.customer_name}`,
          units: building.units,
        }));
        setBuildingInfo(formattedBuilding);
     
      } else {
        console.log("Else data:::", data.message);
      }
    } catch (error) {
      console.error("Error fetching building info:", error);
    }
  }
  
  async function getUnitdata(unit) {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
      }
      const response = await fetch(
        getCriticalUnitServiceDetails +
          `?unitNumber=${
            selected_building?.units.find((item) => item.value === unit.toString())?.text
          }&locationID=${selected_building?.value}`,requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unit info");
      }
      const data = await response.json();
      if (data.code === 200) {
        setNewServiceDate(data.data.last_service_date
          ? moment(data.data.last_service_date).toDate()
          : null)
        // Update var_critical_info with the new data, ensuring state merges correctly
        set_critical_info((prevState) => ({
          ...prevState,
          tech_reported: data.data.tech_reported || "",
          last_tech_comment: data.data.last_tech_comment || "",
        }));
      } else {
        set_critical_info((prevState) => ({
          ...prevState,
          tech_reported:  "",
          last_service_date: null,
          last_tech_comment:  "",
        }));
        console.log("Error:", data.message);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/


function isValidated() {
  var temp_error = { ...errors };
  var isValid = true;
  if (!currentDate) {
    temp_error.dateReportedDate = "Please select date.";
    isValid = false;
  }
  if (selected_building.length === 0) {
    temp_error.selected_building = "Please select building.";
    isValid = false;
  }
  if (building_units.length === 0 ) {
    temp_error.buildingunits = "Please select units.";
    isValid = false;
  }

  if (!var_critical_info || !var_critical_info.hasOwnProperty('tech_reported') || var_critical_info.tech_reported === "") {
    temp_error.tech_reported = "Please add tech reported.";
    isValid = false;
  }

  if (!var_critical_info || !var_critical_info.hasOwnProperty('summary') || var_critical_info.summary === "") {
    temp_error.summary = "Please add summary.";
    isValid = false;
  }

  if(var_critical_info.summary?.length > 500){
    temp_error.summary = "Summary Character limit exceeded.";
    isValid = false;
  }

  if (!var_critical_info || !var_critical_info.hasOwnProperty('csr_actions') || var_critical_info.csr_actions === "") {
    temp_error.csr_actions = "Please add csr's actions.";
    isValid = false;
  }

  if(var_critical_info.csr_actions?.length > 500){
    temp_error.csr_actions = "CSR's action Character limit exceeded.";
    isValid = false;
  }

  if(!ServiceDate){
    temp_error.lastScheduledDate = "Please select service date.";
    isValid = false;
  }

  // if (!var_critical_info || !var_critical_info.hasOwnProperty('ss_rm_steps') || var_critical_info.ss_rm_steps === "") {
  //   temp_error.ss_rm_steps = "Please add next steps.";
  //   isValid = false;
  // }

  
  if(var_critical_info?.ss_rm_steps?.length > 500){
    temp_error.ss_rm_steps = "SS/RM Next Step Character limit exceeded.";
    isValid = false;
  }

  // if (!var_critical_info || !var_critical_info.hasOwnProperty('note') || var_critical_info.note === "") {
  //   temp_error.note = "Please add note.";
  //   isValid = false;
  // }

  if(var_critical_info?.note?.length > 500){
    temp_error.note = "Note Character limit exceeded.";
    isValid = false;
  }

  if (!var_critical_info || !var_critical_info.hasOwnProperty('last_tech_comment') || var_critical_info.last_tech_comment === "") {
    temp_error.last_tech_comment = "Please add last tech comment.";
    isValid = false;
  }

  if(var_critical_info.last_tech_comment?.length > 500){
    temp_error.last_tech_comment = "Last Tech Comment Character limit exceeded.";
    isValid = false;
  }

  if (isValid) {
    set_errors({});
  } else {
    set_errors(temp_error);
  }
  return isValid;
}
function onChangeUnits(event, { value }) {
    // var temp_error = { ...errors };
    // temp_error.buildingunits = null;
    // set_errors(temp_error);
    // const filteredArray = selected_building?.units?.filter(unit => value.includes(unit.value));
    getUnitdata(value)
    setBuildingUnits(value);
}


const convertDateFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString();
};



const onChange = (event, data) => {
  if (data?.value) {
    try {
      // Parse the date using moment and convert it to a Date object
      const parsedDate = moment(data.value).toDate();
      
      // If the date is valid, update the state and reset the error
      if (!isNaN(parsedDate.getTime())) {
        var temp_error = { ...errors };
        temp_error.currentDate = null; // Reset the specific error
        set_errors(temp_error);
        setNewDate(parsedDate); // Set the date directly as a Date object
      } else {
        throw new Error("Invalid date");
      }
    } catch (error) {
      console.error("Date parsing error:", error.message);
    }
  }
  };
  
  const onChangeLastServiceDate = (event, data) => {
    if (data?.value) {
      try {
        // Parse the date using moment and convert it to a Date object
        const parsedDate = moment(data.value).toDate();
        
        // If the date is valid, update the state and reset the error
        if (!isNaN(parsedDate.getTime())) {
          var temp_error = { ...errors };
          temp_error.lastScheduledDate = null; // Reset the specific error
          set_errors(temp_error);
          setNewServiceDate(parsedDate); // Set the date directly as a Date object
        } else {
          throw new Error("Invalid date");
        }
      } catch (error) {
        console.error("Date parsing error:", error.message);
      }
    }
  };

// const onChangeInput = (event) => {
//   let name = event.target.name;
//   let value = event.target.value;
// }

function handleSearchChange(event, data) {
  const { searchQuery } = data;
  fetchBuildingInfo(searchQuery);
}

function onChangeBuilding(event, { value }) {
  var temp_error = { ...errors };
  temp_error.selected_building = null;
  set_errors(temp_error);
  setSelectedBuilding(buildingInfo.filter((f_v) => f_v.value === value)[0]);
}

const onChangeInput = (event) => {
  let name = event.target.name;
  let value = event.target.value;

  // Check if the value exceeds 500 characters
  if (value.length > 500) {
    // Set error for exceeding character limit
    set_errors((prevErrors) => ({
      ...prevErrors,
      [name]: 'Character limit exceeded',
    }));
  } else {
    // Clear the error if within limit
    set_errors({});
  }

    set_critical_info({ ...var_critical_info, [name]: value });
};


async function handleDeleteCriticalUnit() {
  set_mdl_progress(true);
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
    };
    var apiUrl=deleteCriticalUnit+`${var_critical_info?.uuid}`;
    await fetch(apiUrl,requestOptions)
    .then(response=>response.json())
    .then(res=>{
        const{success,code}=res;
        if(code===200){
            addToast(success, {
                appearance: 'success',
                autoDismiss: true,
            })
            //get_all_critical_list();
        }
        else{
            addToast(success, {
                appearance: 'error',
                autoDismiss: true,
            })
        }
        
    })
    .catch(error=>{
        console.error("Error:",error);
    })
    set_mdl_open(false)
    set_mdl_progress(false);
}


function handleResolveCriticalUnit(data) {
  if(isValidated()){
    var_critical_info.status = "resolved";
  }
  UpdateCriticalData()
  //addToast("Critical Unit Resolved", { appearance: "success" });
  
}

async function UpdateCriticalData() {
  if(isValidated()){
  
  var_critical_info.buildingID = selected_building?.value;
  var_critical_info.building_address = selected_building?.text;
  var_critical_info.unit_number = selected_building?.units.find(item => item.value === building_units.toString())?.text;
  var_critical_info.date_reported = convertDateFormat(currentDate).replace('Z','');
  var_critical_info.last_service_date = convertDateFormat(ServiceDate).replace('Z','');
  set_mdl_progress(true);
   const requestWarranty = {
       method: "PUT",
       headers: {
         "Content-Type": "application/json",
         Authorization: "Token " + user.token,
       },
       body: JSON.stringify(var_critical_info),
   };
   await fetch(UpdateCriticalUnitData+var_critical_info?.uuid, requestWarranty)
     .then((response) => response.json())
     .then((res) => {
       if (res.code == 200) {
         addToast(res.success, {
           appearance: "success",
           autoDismiss: true,
         });
         //get_all_critical_list();
       }else {
         addToast(res.success, {
           appearance: "error",
           autoDismiss: true,
         });
       }
     }).catch((error) => {
       console.log("Errors:", error);
     });
     set_mdl_open(false)
     set_mdl_progress(false);
  }
 };
  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
        <div className="modal-header">
            <div className="modal-header-title">Update Critical Unit Details</div>
            <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                <Icon name="times circle outline" />
            </div>
        </div>
        <div className="modal-content  fields">
              <label htmlFor="sortby_options">Location ID or Building Address: </label>
              <Dropdown
                search
                selection
                className="sortby_options"
                id="sortby_options"
                name="sortby_options"
                placeholder="Select Building"
                label="Building Address: "
                onSearchChange={handleSearchChange}
                onChange={onChangeBuilding}
                value={selected_building?.value}
                options={buildingInfo}
                error={errors.selected_building}
                disabled={SingleCriticalData?.status === "ongoing" ? false : true}
              />
              <label htmlFor="sortby_options">Client: </label>
              <Form.Field
                  id='client'
                  required={true}
                  name='client'
                  placeholder='Client'
                  style={{width:'100%'}}
                  disabled={true}
                  control={Input}
                  value={selected_building?.customer_name}
              />
              <label htmlFor="sortby_options">Unit: </label>
              <Dropdown
                search
                style={{width:'100%'}}
                selection
                className="sortby_units"
                id="sortby_units"
                name="sortby_units"
                placeholder="Select Units"
                label="Units: "
                onChange={onChangeUnits}
                value={building_units}
                error={errors.buildingunits}
                options={selected_building?.units}
                disabled={SingleCriticalData?.status === "ongoing" && selected_building?.value ? false : true}
                />
                <label htmlFor="sortby_options">Date Reported: </label>
                <SemanticDatepicker
                style={{width:'100%'}}
                id="dateReportedDate"
                name="dateReportedDate"
                placeholder={'Date Reported'}
                fluid
                value={currentDate}
                error={errors?.dateReportedDate}
                disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                onChange={onChange}
                />
                <label htmlFor="sortby_options">Tech Reported: </label>
                <Form.Field
                  id='tech_reported'
                  name='tech_reported'
                  placeholder='Tech Reported'
                  style={{width:'100%'}}
                  control={Input}
                  required={true}
                  value={var_critical_info?.tech_reported}
                  error={errors["tech_reported"]}
                  disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                  onChange={onChangeInput}
                />
                <label htmlFor="sortby_options">Summary of Issue: </label>
                <Form.Field>
                <Form.TextArea
                    id='summary'
                    style={{width:'100%',height: '40px', borderColor: errors["summary"] ? 'red' : 'black',backgroundColor: errors["summary"] ? '#fff6f6' : 'white'}}
                    name='summary'
                    required={true}
                    value={var_critical_info?.summary}
                    error={errors["summary"]}
                    disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.summary?.length > 500 ? 'red' : 'gray' }}>
                  {errors["summary"] ? 0 : 500 - (var_critical_info?.summary?.length || 0)} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">Last Scheduled Service Date: </label>
                <SemanticDatepicker
                    style={{width:'100%'}}
                    id="lastScheduledDate"
                    name="lastScheduledDate"
                    placeholder={'Date'}
                    required={true}
                    fluid
                    value={ServiceDate}
                    error={errors?.lastScheduledDate}
                    disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                    onChange={onChangeLastServiceDate}
                />
                <label htmlFor="sortby_options">CSR's Action: </label>
                <Form.Field>
                <Form.TextArea
                    id='csr_actions'
                    style={{width:'100%',height: '40px', borderColor: errors["csr_actions"] ? 'red' : 'black',backgroundColor: errors["csr_actions"] ? '#fff6f6' : 'white'}}
                    name='csr_actions'
                    required={true}
                    value={var_critical_info?.csr_actions}
                    error={errors["csr_actions"]}
                    disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                    onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.csr_actions?.length > 500 ? 'red' : 'gray' }}>
                  {errors["csr_actions"] ? 0 : 500 - (var_critical_info?.csr_actions?.length || 0)} characters left
                </div>
                </Form.Field>
                <label htmlFor="sortby_options">SS/RM Next Step: </label>
                <Form.Field>
                <Form.TextArea
                    id='ss_rm_steps'
                    style={{width:'100%',height: '40px'}}
                    name='ss_rm_steps'
                    required={true}
                    value={var_critical_info?.ss_rm_steps}
                    error={errors["ss_rm_steps"]}
                    disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                    onChange={onChangeInput}
                />
                 <div style={{ textAlign: 'right', color: var_critical_info?.ss_rm_steps?.length > 500 ? 'red' : 'gray' }}>
                  {errors["ss_rm_steps"] ? 0 :500 - (var_critical_info?.ss_rm_steps?.length || 0)} characters left
                </div>
              </Form.Field>
                <label htmlFor="sortby_options">Note: </label>
                <Form.Field>
                <Form.TextArea
                    id='note'
                    style={{
                      width: '100%',
                      height: '40px'}}
                    name='note'
                    value={var_critical_info?.note}
                    onChange={onChangeInput}
                    disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                    error={errors["note"]}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.note?.length > 500 ? 'red' : 'gray' }}>
                  {errors["note"] ? 0 :500 - (var_critical_info?.note?.length || 0)} characters left
                </div>
              </Form.Field>
                <label htmlFor="sortby_options">Last Tech Comment: </label>
                <Form.Field>
                <Form.TextArea
                  id='last_tech_comment'
                  width={20}
                  style={{
                    width: '100%',
                    height: '110px',
                    borderColor: errors["last_tech_comment"] ? 'red' : 'black',backgroundColor: errors["last_tech_comment"] ? '#fff6f6' : 'white'
                  }}
                  name='last_tech_comment'
                  placeholder='Last Tech Comment'
                  value={var_critical_info?.last_tech_comment}
                  error={errors["last_tech_comment"]}
                  disabled={SingleCriticalData?.status === "ongoing" ? false : true}
                  onChange={onChangeInput}
                />
                <div style={{ textAlign: 'right', color: var_critical_info?.last_tech_comment?.length > 500 ? 'red' : 'gray' }}>
                  {errors["last_tech_comment"] ? 0 :500 - (var_critical_info?.last_tech_comment?.length || 0)} characters left
                </div>
              </Form.Field>
            
        </div>
        <div className="modal-footer">
          {SingleCriticalData?.status === "ongoing" && (
            <><Button type="submit" color="blue" onClick={()=>UpdateCriticalData()} loading={isLoading}>
            Update
           </Button>
            
            <Button type="submit" color="green" onClick={()=>handleResolveCriticalUnit()} loading={isLoading}>
             Resolved
            </Button>
            <Button type="submit" color="red" onClick={()=>handleDeleteCriticalUnit()} loading={isLoading}>
             Delete
            </Button>
            </>
            )}
        </div>

         {/***** MODAL: PROGRESS **************************************************************************/}
         <Modal
            id="mdl-progress"
            dimmer={"inverted"}
            open={var_mdl_progress}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            onClose={() => {}}
          >
            <MDL_PROGRESS
              set_mdl_open={set_mdl_progress}
              var_modaltitle="Please wait"
              var_message={"Stay on this page until this process finish."}
            ></MDL_PROGRESS>
          </Modal>

        {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
)
};
export default MDL_UPDATE_CRITICAL_UNIT;
