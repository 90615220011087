import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Label, Modal, Button } from "semantic-ui-react";
import { orderDetailsGet } from "../../../constants";
import "./complete-order.css";

function CompleteOrder() {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState();

  useEffect(() => {
    if (location.state && location.state.id) {
      getOrderDetails();
    } else {
      history.goBack();
    }
  }, []);

  async function getOrderDetails() {
    var id = location.state.id;
    var unit_number = location.state.unit_number;
    await fetch(orderDetailsGet + "?id=" + id + "&unit_number=" + unit_number)
      .then((resposnse) => resposnse.json())
      .then((res) => {
        console.log("SCEDULED DATE....", res.data.schedule_date);
        if (res.code === 200) {
          setData(res.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="app-content">
      <div className="order-review-contaienr">
        <div className="order-review-header">
          <div className="order-review-title">View Treatment Request</div>
          <div className="order-review-subtitle">
            <span>Building:</span>
            <span>{data ? data.building_name : "No name"}</span>
          </div>
          <div className="order-review-subtitle">
            <span>Date &amp; Time: </span>
            <span>{data ? data.order_date_time : "No date"}</span>
          </div>
          {data && data.building_po_mo_wo && (
            <div className="order-review-subtitle">
              <span>
                {data.po_mo_wo.toString().toLowerCase() === "po"
                  ? "Purchase Order Number"
                  : data.po_mo_wo.toString().toLowerCase() === "mo"
                  ? "Maintenance Order Number"
                  : "Work Order Number"}
              </span>
              <span>
                {data.purchase_order_number ? data.purchase_order_number : 0}
              </span>
            </div>
          )}
          <div className="order-review-subtitle">
            <span>Status: </span>
            <span style={{ textTransform: "capitalize" }}>
              {data?.order_status || "No Status"}
            </span>
          </div>
          <div className="order-review-subtitle">
            <span>Scheduled Date: </span>
            <span>
              {data && data.schedule_date
                ? moment(data.schedule_date).format("YYYY-MM-DD")
                : "N/A"}
            </span>
          </div>
        </div>
        {data &&
          data.units.map((vals) => (
            <div className="order-review-unit">
              <div className="app-card">
                <div className="unit-detail-row">
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Unit #</div>
                    <div className="unit-detail-value">{vals.unit_number}</div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Pest Requested</div>
                    <div className="unit-detail-value">
                      {vals.pest_name ? vals.pest_name : "No name"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Treatment Requested</div>
                    <div className="unit-detail-value">
                      {vals.treatment_name ? vals.treatment_name : "No name"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">
                      Is this a follow up treatment for this unit?
                    </div>
                    <div className="unit-detail-value">
                      {vals.follow_up ||
                      vals.isFollowupMonthly ||
                      vals.isImmediate === 1
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Notes</div>
                    <div className="unit-detail-value">
                      {vals.order_description
                        ? vals.order_description
                        : "No notes"}
                    </div>
                  </div>
                  <div className="unit-detail-col">
                    <div className="unit-detail-label">Time Window</div>
                    <div className="unit-detail-value">
                      {vals.time_range ? vals.time_range : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        <div className="page-form-submit">
          <div className="page-form-submit-button">
            <Button color="red" onClick={history.goBack}>
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteOrder;
